import React, { Component } from "react"
import TNGHome from "../components/authPages/TNGHome"

interface Props {
  location: any
}

export default class TNGPage extends Component<Props> {
  render() {
    return <TNGHome />
    // return <AuthWrapper component="TNG" location={this.props.location} />
  }
}
